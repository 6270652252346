import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El capell d’aquest bolet és més o manco circular en forma d’embut, coriaci, cobert en superfície per esquametes o llengüetes alçades i de fins a 5 cm de diàmetre. Freqüentment es troba ajuntat a altres capells veïns. De jove sol tenir unes gotetes vermelles o color roig sang sobre la superfície, la qual té un color blanquinós rosat de jove i després torna marronós. El peu és cilíndric, ple, de color més clar que el capell. Les espores són marronenques en massa, rodones amb berrugues molt marcades, de 4,5-6 x 4,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      